<template>
<div :class="$style.root">
  <div class="px-4 pt-6 pb-3">
    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        outlined
        dense
        label="Поиск"
        hide-details
    />
  </div>
  <v-data-table
      fixed-header
      must-sort
      loading-text="Загрузка... Пожалуйста, подождите"
      :search="search"
      :loading="pending"
      :headers="[
        {text: 'Название', value: 'name'},
        {text: 'Код', value: 'code'},
        {text: 'Сумма', value: 'amount'},
      ]"
      :itemsPerPage="20"
      :mobile-breakpoint="0"
      :height="height"
      :items="items"
      :item-class="() => $style.row"
      :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 100, 500, -1],
          itemsPerPageText: `Всего ${total} руб`,
        }"
  />
  <div v-if="pending" :class="$style.loader" />
</div>
</template>

<script>
import {sumBy, round} from 'lodash';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'CisLinkTable',
  props: {
    inn: { type: String },
  },
  data() {
    return {
      search: '',
      height: window.innerHeight - 210,
    }
  },
  computed: {
    ...mapGetters({
      pending: 'cislink/pending',
      items: 'cislink/all',
    }),
    total() {
      return round(sumBy(this.items, 'amount'), 2);
    }
  },
  methods: {
    ...mapActions({
      fetchSellOut: 'cislink/fetchSellOut',
    }),
    resize() {
      this.height = window.innerHeight - 210;
    },
  },
  beforeMount() {
    this.fetchSellOut(this.inn);
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    inn() {
      this.fetchSellOut(this.inn);
    }
  }
}
</script>

<style module lang="scss">
.root {
  position: relative;
  .loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :global {
    .v-data-footer{
      justify-content: flex-start;
      padding-left: 16px;
      .v-data-footer__select {
        flex-grow: 1;
        font-size: 18px !important;
        justify-content: space-between;
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }

      .v-select {

        .v-input {

        }
      }
    }
  }
}
</style>
