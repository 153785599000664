var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('div',{staticClass:"px-4 pt-6 pb-3"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","outlined":"","dense":"","label":"Поиск","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"fixed-header":"","must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","search":_vm.search,"loading":_vm.pending,"headers":[
        {text: 'Название', value: 'name'},
        {text: 'Код', value: 'code'},
        {text: 'Сумма', value: 'amount'} ],"itemsPerPage":20,"mobile-breakpoint":0,"height":_vm.height,"items":_vm.items,"item-class":function () { return _vm.$style.row; },"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 100, 500, -1],
          itemsPerPageText: ("Всего " + _vm.total + " руб"),
        }}}),(_vm.pending)?_c('div',{class:_vm.$style.loader}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }